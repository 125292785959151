import type { CrewMemberStoryblok } from '@/types/component-types-sb'
import type { StoryblokStory } from 'storyblok-generate-ts'
import { useStoryblokApi } from '@storyblok/vue'

export const useMembersPending = () => {
  return useState('membersPending', () => false)
}
const useMembers = (): Ref<StoryblokStory<CrewMemberStoryblok>[]> => {
  return useState('members', () => [])
}

export const getMemberById = (
  id: string,
  members = useMembers(),
  pending = useMembersPending(),
) => {
  if (!pending.value && members.value.length === 0) {
    fetchMembers().then(() => {
      pending.value = false
    })
  }
  return members.value?.find((member) => member.uuid === id)
}

export const fetchMembers = async (
  members = useMembers(),
  pending = useMembersPending(),
) => {
  const storyblokApi = useStoryblokApi()
  if (process.client) {
    pending.value = true
  }
  const response = await storyblokApi.get('cdn/stories/', {
    content_type: 'crew-member',
    version: getStoryblokVersion(),
    per_page: 100,
  })

  members.value = response.data.stories
}
